.img-icono-sisdesa img{
    width: 70%;
}
.img-icono-sisdesa-samll img{
    width: 60%;
}
nav.navbar .nav-item > a{
    width: 40px;
}
nav.fixet-top{

    border-bottom: 1px solid black;
}